import * as React from "react";
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";

const StudentSideBar = () => {
  const location = useLocation();
  return (
    <>
      <React.Fragment>
        {/* <ListItemButton component={Link} to="/admin/dashboard">
          <ListItemIcon>
            <HomeIcon
              color={
                location.pathname === ("/" || "/admin/dashboard")
                  ? "primary"
                  : "light"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton> */}
        <ListItemButton component={Link} to="/Admin/students">
          <ListItemIcon>
            <AssignmentIcon
              color={
                location.pathname.startsWith("/Admin/students")
                  ? "primary"
                  : "light"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Students" />
        </ListItemButton>
        <ListItemButton component={Link} to="/Admin/register">
          <ListItemIcon>
            <AssignmentIcon
              color={
                location.pathname.startsWith("/Admin/register")
                  ? "primary"
                  : "light"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Register Students" />
        </ListItemButton>
        <ListItemButton component={Link} to="/admin/certificate">
          <ListItemIcon>
            <AssignmentIcon
              color={
                location.pathname.startsWith("/admin/certificate")
                  ? "primary"
                  : "inherit"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Cerificates" />
        </ListItemButton>
        <ListItemButton component={Link} to="/Admin/registration">
          <ListItemIcon>
            <AssignmentIcon
              color={
                location.pathname.startsWith("/Admin/registration")
                  ? "primary"
                  : "light"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Registration" />
        </ListItemButton>
        {/* <ListItemButton component={Link} to="/Student/attendance">
          <ListItemIcon>
            <ClassOutlinedIcon
              color={
                location.pathname.startsWith("/Student/attendance")
                  ? "primary"
                  : "inherit"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Status" />
        </ListItemButton> */}
      </React.Fragment>
      <Divider sx={{ my: 1 }} />
      <React.Fragment>
        {/* <ListSubheader component="div" inset>
          User
        </ListSubheader> */}
        <ListItemButton component={Link} to="/admin/profile">
          <ListItemIcon>
            <AccountCircleOutlinedIcon
              color={
                location.pathname.startsWith("/admin/profile")
                  ? "primary"
                  : "inherit"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
        {/* <ListItemButton component={Link} to="/logout">
          <ListItemIcon>
            <ExitToAppIcon
              color={
                location.pathname.startsWith("/logout") ? "primary" : "inherit"
              }
            />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton> */}
      </React.Fragment>
    </>
  );
};

export default StudentSideBar;
