/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import BasicSelect from "./Select";
import DataTable from "./DataTable";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";
import { getStudentsStart } from "../../pages/slice";
import MultipleSelect from "./MultiSelectorDemo";
import axios from "axios";

const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];
const ViewStudent = () => {
  const [open, setOpen] = useState(false);
  const [filterDepartemnt, setFilteredDepartment] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [failur, setShowFailur] = useState(false);
  const students = useSelector((state) => state.student.students);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedModality, setSelectedModality] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [data, setData] = useState({
    batch: [],
    department: [],
    program: [],
    modality: [],
  });
  const [departmentData, setDepartments] = useState([]);
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };

    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batches`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchProgram();
    fetchDepartment();
    fetchBatch();
  }, []);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
  };

  const handleSearch = () => {
    // Split comma-separated string into arrays
    const selectedYearsString = data.batch ? data.batch.join(",") : "";
    const departmentString = data.department.join(",");
    const programString = data.program.join(",");
    const modalityString = data.modality.join(",");
    console.log(data);
    dispatch(
      getStudentsStart({
        batch: selectedYearsString,
        department: departmentString,
        program: programString,
        modality: modalityString,
      })
    );
  };
  const handleReset = () => {
    setSelectedYears([]); // Reset the selected years to an empty array
    setSelectedDepartments([]);
    dispatch(
      getStudentsStart({ batch: "", department: "", program: "", modality: "" })
    );
  };
  useEffect(() => {
    dispatch(
      getStudentsStart({ batch: "", department: "", program: "", modality: "" })
    );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "department" || name === "modality" || name === "batch") {
      // For multi-select fields, the selected options are returned as an array
      setData({
        ...data,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      // For other fields, the value is a single string
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(false);
      setShowFailur(false);
      // setError(null);
    }, 6000);
  }, [showPopup, failur]);
  return (
    <div className="container-fluid mt-4 w-80">
      <div className=" p-3 " aria-labelledby="searchDropdown">
        {showPopup && <Alert variant="success">{message}</Alert>}
        {failur && <Alert variant="danger">{message}</Alert>}
        <form className="form-inline mr-auto w-50 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light border-0 small mt-3 ml-2"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={handleSearchInputChange}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary mt-3"
                type="button"
                style={{ height: "35px", width: "50px" }}
              >
                <i className="fas fa-search fa-sm">e</i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        className="d-sm-flex align-items-center justify-content-between mb-4"
        style={{ width: "100%" }}
      >
        <h1 className="h3 mb-5 text-gray-800">Students</h1>
      </div>
      {/* <div
        className="d-flex"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          // gap: "50px",
          marginTop: "`10%",
        }}
      > */}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <MultipleSelect
            data={departmentData}
            name="department"
            value={data.department}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <MultipleSelect
            data={batchData}
            name="batch"
            value={data.batch}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <MultipleSelect
            data={modalities}
            name="modality"
            value={data.modality}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <MultipleSelect
            data={programData}
            name="program"
            value={data.program}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={3}
          style={{ textAlign: "center", marginTop: "-28px" }}
        >
          <Button
            variant="contained"
            style={{ color: "#d9d5d4", marginRight: "10px" }}
            onClick={handleSearch}
          >
            apply
          </Button>
          <Button
            variant="contained"
            style={{ color: "#d9d5d4" }}
            onClick={handleReset}
          >
            reset
          </Button>
          {/* </div> */}
        </Grid>
      </Grid>
      {/* </div> */}
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <DataTable studentData={students} />
      </div>
    </div>
  );
};

export default ViewStudent;
