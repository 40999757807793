import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const list = ["Male", "Female"];

const program = ["Certificate", "Diploma", "BA Degree", "Master's"];

export default function BasicSelect({ label, onChange, name, value }) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Sex"
          onChange={onChange}
          name={name}
        >
          {name == "sex"
            ? list.map((sex) => (
                <MenuItem key={sex} value={sex}>
                  {sex}
                </MenuItem>
              ))
            : program.map((program) => (
                <MenuItem key={program} value={program}>
                  {program}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </Box>
  );
}
