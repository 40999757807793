import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { rootSaga } from "./rootSaga.js";
import { theme } from "./styles/theme.js";
import student from "./pages/slice/index.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
const saga = createSagaMiddleware();
const store = configureStore({
  reducer: { student },
  middleware: [saga],
});
saga.run(rootSaga);
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
