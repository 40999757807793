import { Button } from "react-bootstrap";
import PreviwModal from "../pdfComponent/Preview";
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

const DocumentViewer = ({ pdf }) => {
  const [showModal, setShowModal] = useState(false); // Local state for each modal

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{ margin: "20px 0" }}>
      <div
        className="document-viewer"
        style={{
          width: "250px",
          height: "300px",
          overflow: "hidden",
        }}
      >
        <embed src={pdf.pdfUrl} width="100%" height="100%" />
      </div>
      <h5 style={{ marginTop: "10px" }}>
        {pdf.name} ({pdf.studentIDs?.length})
      </h5>
      <Button
        onClick={() => setShowModal(true)} // Open modal for this specific PDF
        style={{ marginRight: "30px", marginTop: "20px" }}
      >
        Preview PDF
      </Button>
      <PreviwModal
        setShowModal={setShowModal}
        showModal={showModal} // Modal state for this specific PDF
        previewUrl={pdf.pdfUrl} // Pass the clicked PDF URL
        pdfName={pdf.name} // Pass the clicked PDF name
      />
    </Grid>
  );
};

const PDFGallery = () => {
  const [pdfs, setPDFs] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // Fetch PDF data from backend
    fetch("https://alliance-certificate.onrender.com/api/users/pdfs")
      .then((response) => response.json())
      .then((data) => {
        setPDFs(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching PDFs:", error);
      });
  }, []);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
  };

  const filteredPDFs = pdfs.filter((pdf) =>
    pdf.name.toLowerCase().includes(searchValue)
  );

  return (
    <div>
      <div className="p-3" aria-labelledby="searchDropdown">
        <form className="form-inline mr-auto w-50 navbar-search">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-light border-0 small mt-3 ml-2"
              placeholder="Search for..."
              aria-label="Search"
              onChange={handleSearchInputChange}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary mt-3"
                type="button"
                style={{ height: "35px", width: "50px" }}
              >
                <i className="fas fa-search fa-sm">e</i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <Grid
        container
        spacing={3}
        className="pdf-gallery"
        style={{ margin: "1%" }}
      >
        {filteredPDFs.map((pdf) => (
          <DocumentViewer key={pdf._id} pdf={pdf} />
        ))}
      </Grid>
    </div>
  );
};

export default PDFGallery;
