import ViewStudent from "../components/Ui/AdminHomePage";
import AdminDashboard from "../components/Ui/Dashboard";
import PDFList from "../components/Ui/PDFList";
import Profile from "../components/Ui/Profile";
import CollectionComponet from "../components/registration/CollectionComponent";
import StudentRegistrationForm from "../components/student/Register";

export const routes = [
  {
    element: <AdminDashboard />,
    exact: true,
    path: "/admin/dashboard",
    isProtected: true,
    allowedRole: "admin",
  },
  {
    element: <ViewStudent />,
    exact: true,
    path: "/admin/students",
    isProtected: true,
    allowedRole: "admin",
  },
  {
    element: <PDFList />,
    exact: true,
    path: "/admin/certificate",
    isProtected: true,
    allowedRole: "admin",
  },
  {
    element: <StudentRegistrationForm />,
    exact: true,
    path: "/admin/register",
    isProtected: true,
    allowedRole: "admin",
  },
  {
    element: <CollectionComponet />,
    exact: true,
    path: "/admin/registration",
    isProtected: true,
    allowedRole: "admin",
  },
  {
    element: <Profile />,
    exact: true,
    path: "/admin/profile",
    isProtected: true,
    allowedRole: "admin",
  },
];
