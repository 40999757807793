import { takeLatest, call, put } from "redux-saga/effects";
import {
  deleteStudentFailure,
  deleteStudentSuccess,
  getSingleStudentFailure,
  getSingleStudentSuccess,
  getStudentsFailure,
  getStudentsSuccess,
  setError,
  setStatus,
  setStudentsFailure,
  setStudentsSuccess,
  updateStudentFailure,
  updateStudentsSuccess,
} from "./index";
import axios from "axios";
import { apiBaseUrl } from "../../utils/constants";

const fetchCourses = async (batch, department, program, modality) => {
  const response = await fetch(
    `${apiBaseUrl}/users/student-class?batch=${batch}&department=${department}&program=${program}&modality=${modality}&role=student`
  );
  return response.json();
};
const fetchSingleCourses = async (id) => {
  const response = await fetch(`${apiBaseUrl}/course/${id}`);
  return response.json();
};

const deleteCourse = async (id) => {
  const response = await fetch(`${apiBaseUrl}/course/delete/${id}`, {
    method: "DELETE",
  });
  return response.json();
};

const updateCourse = async (id, courseData) => {
  const response = await fetch(`${apiBaseUrl}/course/update/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(courseData),
  });
  return response.json();
};

function* getCoursesSaga(action) {
  try {
    const { batch, department, modality, program } = action.payload;
    console.log("query", action.payload);
    const students = yield call(
      fetchCourses,
      batch,
      department,
      program,
      modality
    );
    yield put(getStudentsSuccess(students));
  } catch (error) {
    yield put(getStudentsFailure(error.message));
  }
}
function* getSigleCoursesSaga(action) {
  const { id } = action.payload;
  try {
    // yield put(getCoursesStart());
    const courses = yield call(fetchSingleCourses(id));
    yield put(getSingleStudentSuccess(courses));
  } catch (error) {
    yield put(getSingleStudentFailure(error.message));
  }
}

function* deleteCourseSaga(action) {
  try {
    const { id } = action.payload;
    yield call(deleteCourse, id);
    yield put(deleteStudentSuccess(id));
  } catch (error) {
    yield put(deleteStudentFailure(error.message));
  }
}

function* updateCourseSaga(action) {
  try {
    const { id, courseData } = action.payload;
    console.log(courseData);
    console.log(id);
    const updatedCourse = yield call(updateCourse, id, courseData);
    yield put(updateStudentsSuccess(updatedCourse));
  } catch (error) {
    yield put(updateStudentFailure(error.message));
  }
}

// function* setCourseSaga(action) {
//   try {
//     const { data } = action.payload;
//     yield put(setCourseStart());
//     const setCourse = yield call(setCourse, data);
//     yield put(setCoursesSuccess(setCourse));
//   } catch (error) {
//     yield put(setCoursesFailure(error.message));
//   }
// }

function* setStudent(action) {
  console.log(action.payload);
  try {
    const res = yield call(() =>
      axios.post(`${apiBaseUrl}/users/register`, action.payload.studentData)
    );
    // yield put(setStudentsSuccess(res));
    // console.log(res);
    if (res.data.status == 409) {
      yield put(setError("User with given Id already Exist!"));
    }
    yield put(setStatus(res.data.message));
  } catch (error) {
    console.log(error);
    // yield put(setStudentsFailure(error));
    yield put(setError(error.message));
  }
}

function* studentSaga() {
  yield takeLatest("students/getSingleStudentStart", getSigleCoursesSaga);
  yield takeLatest("students/getStudentsStart", getCoursesSaga);
  yield takeLatest("students/deleteStudentStart", deleteCourseSaga);
  yield takeLatest("students/updateStudentStart", updateCourseSaga);
  yield takeLatest("students/setStudentsStart", setStudent);
}

export default studentSaga;
