import React, { useState } from "react";
import axios from "axios";
import moment from "moment";

const Certificate = ({
  name,
  course,
  dateOfConductStart,
  dateOfConductEnd,
  signatureDetails,
  firstName,
}) => {
  const [pdfData, setPdfData] = useState(null);

  const handleGeneratePDF = async () => {
    try {
      // const htmlContent = `
      //   <div class=${styles.certificateWrapper}>
      //     <div class=${styles.certificateContainer}>
      //       <img src="/public/logo.jpg" style={{ width: "200px", height: "200px", borderRadius: "50%" }} />
      //       <h1>CERTIFICATE OF APPRECIATION</h1>
      //       <span class=${
      //         styles.smallText
      //       }>This certificate is proudly awarded to</span>
      //       <p class=${styles.primaryItalicText}>${firstName}</p>
      //       <span class=${
      //         styles.smallText
      //       }>for successfully completing the course</span>
      //       <h2>${course}</h2>
      //       <span class=${styles.smallText}>${`conducted from ${
      //   dateOfConductStart
      //     ? moment(dateOfConductStart).format("MMMM YYYY")
      //     : "-"
      // } to ${
      //   dateOfConductEnd ? moment(dateOfConductEnd).format("MMMM YYYY") : "-"
      // }`}</span>
      //       <div class=${styles.signatureBlock}>
      //         <span class=${styles.smallText}>${signatureDetails}</span>
      //       </div>
      //     </div>
      //   </div>
      // `;

      const htmlContent = `
      <div
      style="
        background-color: #fff;
        /* padding: 2rem; */
        color: #000;
        width: 98vw;
        height: 100vh;
        text-align: center;
        box-sizing: border-box;
      "
    >
      <div
        style="
          border: 10px solid #000;
          padding: 2rem;
          display: flex;
          height: 98%;
          flex-direction: column;
          /* background-image: url('https://media.istockphoto.com/id/172740259/photo/certificate-frame.jpg?s=612x612&w=0&k=20&c=KFd7Rd-F_pAjGs-3K99pN_KXT6hWM9MlKLZjiBO22hw=');
          background-size: cover;
          background-position: center;
          background-attachment: cover; */
          box-sizing: border-box;
        "
      >
        <div style="display: flex; justify-content: space-between">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/genius-eth.appspot.com/o/question_paper_images%2Flogo.jpg?alt=media&token=a0ada078-4076-4ddb-bb45-30cfdfe13db6"
            style="width: 200px; height: 200px; border-radius: 50%"
          />
          <div>
            <h1
              style="
                color: #00f;
                font-size: 3.5rem;
                font-weight: 700;
                font-style: italic;
                margin-block: 1rem;
              "
            >
              CERTIFICATE OF APPRECIATION
            </h1>
            <span style="font-size: 1rem; margin-bottom: 1rem"
              >This certificate is proudly awarded to</span
            >
          </div>
          <div></div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;
          "
        >
          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: start;
              padding-right: 20px;
            "
          >
            <p
              style="
                color: #00f;
                font-size: 1.5rem;
                font-weight: 700;
                font-style: italic;
                margin-block: 1rem;
              "
            >
              To ______________________________________
            </p>
            <span style="font-size: 1.4rem"
              >በቴክኒክና ሙያ ትምህርትና ሥልጠና ፕሮግራማችን የ [መምሪያ/ መስክ] ትምህርት በስኬት መጠናቀቁን ስንገልጽ በጣም ደስ ብሎናል። ከ(መጀመሪያ አመት) እስከ (መጨረሻ አመት)፣ የቁርጥ ቀን ተማሪዎቻችን ወደር የለሽ ቁርጠኝነት እና ጉጉትን በማሳየት በጠንካራው የ[መምሪያ/መስክ] ስርአተ ትምህርት ውስጥ ተጠምቀዋል። በዚህ ጊዜ ውስጥ ክህሎቶቻቸውን እና እውቀታቸውን ከፍ አድርገው በመረጡት የስራ ዘርፍ የላቀ ብቃት ለማዳበር ዝግጁ ሆነው ብቁ ባለሙያዎች ሆነዋል። በአርአያነት ባለው አፈጻጸም፣ ተማሪዎቻችን የሚያስመሰግኑ የውጤት ነጥቦችን አግኝተዋል፣ ይህም የትጋት እና ታታሪነት ማረጋገጫ ነው። ይህ ምእራፍ የአካዳሚክ ውጤቶቻቸውን የሚያከብር ብቻ ሳይሆን በ[ክፍል/መስክ] ውስጥ ሙያዎችን ለማሟላት ተስፋ ሰጪ ጉዞ መጀመሩን ያሳያል።</span
            >
            <!-- <h2>CourseName</h2>
            <span style="font-size: 1rem">DateRange</span>
            <div style="margin-top: 3rem; display: inline-block">
              <span style="font-size: 1rem">SignatureDetails</span>
            </div> -->
          </div>
          <div style="width: 10px; background-color: black; height: 100%"></div>
          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: start;
              padding-left: 20px;
            "
          >
          <p
          style="
            color: #00f;
            font-size: 1.5rem;
            font-weight: 700;
            font-style: italic;
            margin-block: 1rem;
          "
        >
          To ______________________________________
        </p>
        <span style="font-size: 1.4rem"
          >We are delighted to announce the successful completion of the
          [Department/Field] course in our TVET training program. From
          [Start Year] to [End Year], our dedicated students have immersed
          themselves in the rigorous curriculum of [Department/Field],
          demonstrating unparalleled commitment and enthusiasm. Throughout
          this period, they have honed their skills and expertise, emerging
          as proficient professionals ready to excel in their chosen fields.
          With exemplary performance, our students have earned commendable
          grade points, a testament to their diligence and hard work. This
          milestone not only celebrates their academic achievements but also
          signifies the beginning of a promising journey towards fulfilling
          careers in [Department/Field]</span
        >
            <!-- <h2>CourseName</h2>
            <span style="font-size: 1rem">DateRange</span>
            <div style="margin-top: 3rem; display: inline-block">
              <span style="font-size: 1rem">SignatureDetails</span>
            </div>
          </div> -->
        </div>
      </div>
      <div style="margin-top: 3rem; display: inline-block">
        <span style="font-size: 1rem">SignatureDetails</span>
      </div>
    </div>
    
      `;

      const response = await axios.post(
        "https://alliance-certificate.onrender.com/api/users/upload-pdf",
        {
          htmlContent,
        }
      );

      setPdfData(response.data);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <>
      {/* <div className={styles.certificateContainer}>
          <img
            src="/public/logo.jpg"
            style={{ width: "200px", height: "200px", borderRadius: "50%" }}
          />

          <h1>CERTIFICATE OF APPRECIATION</h1>

          <span className={styles.smallText}>
            This certificate is proudly awarded to
          </span>

          <p className={styles.primaryItalicText}>{firstName}</p>

          <span className={styles.smallText}>
            for successfully completing the course
          </span>

          <h2>{course}</h2>

          <span className={styles.smallText}>{`conducted from ${
            dateOfConductStart
              ? moment(dateOfConductStart).format("MMMM YYYY")
              : "-"
          } to ${
            dateOfConductEnd
              ? moment(dateOfConductEnd).format("MMMM YYYY")
              : "-"
          }`}</span>

          <div className={styles.signatureBlock}>
            <img
              className={styles.signatureImage}
              // src={signature.preview}
              alt=""
            />

            <span className={styles.horizontalBar} />

            <span className={styles.smallText}>{signatureDetails}</span>
          </div>
        </div> */}
      <div
        style={{
          backgroundColor: "#fff",
          /* padding: "2rem", */ color: "#000",
          width: "98vw",
          height: "100vh",
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            border: "10px solid #000",
            padding: "2rem",
            display: "flex",
            height: "98%",
            flexDirection: "column",
            /* backgroundImage: "url('https://media.istockphoto.com/id/172740259/photo/certificate-frame.jpg?s=612x612&w=0&k=20&c=KFd7Rd-F_pAjGs-3K99pN_KXT6hWM9MlKLZjiBO22hw=')", backgroundSize: "cover", backgroundPosition: "center", backgroundAttachment: "cover", */ boxSizing:
              "border-box",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/genius-eth.appspot.com/o/question_paper_images%2Flogo.jpg?alt=media&token=a0ada078-4076-4ddb-bb45-30cfdfe13db6"
              style={{ width: "200px", height: "200px", borderRadius: "50%" }}
            />
            <div>
              <h1
                style={{
                  color: "#00f",
                  fontSize: "3.5rem",
                  fontWeight: 700,
                  fontStyle: "italic",
                  marginBlock: "1rem",
                }}
              >
                CERTIFICATE OF APPRECIATION
              </h1>
              <span style={{ fontSize: "1rem", marginBottom: "1rem" }}>
                This certificate is proudly awarded to
              </span>
            </div>
            <div></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "50px",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                paddingRight: "20px",
              }}
            >
              <p
                style={{
                  color: "#00f",
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  fontStyle: "italic",
                  marginBlock: "1rem",
                }}
              >
                To ______________________________________
              </p>
              <span style={{ fontSize: "1.4rem" }}>
                በቴክኒክና ሙያ ትምህርትና ሥልጠና ፕሮግራማችን የ [መምሪያ/ መስክ] ትምህርት በስኬት መጠናቀቁን
                ስንገልጽ በጣም ደስ ብሎናል። ከ(መጀመሪያ አመት) እስከ (መጨረሻ አመት)፣ የቁርጥ ቀን ተማሪዎቻችን
                ወደር የለሽ ቁርጠኝነት እና ጉጉትን በማሳየት በጠንካራው የ[መምሪያ/መስክ] ስርአተ ትምህርት ውስጥ
                ተጠምቀዋል። በዚህ ጊዜ ውስጥ ክህሎቶቻቸውን እና እውቀታቸውን ከፍ አድርገው በመረጡት የስራ ዘርፍ
                የላቀ ብቃት ለማዳበር ዝግጁ ሆነው ብቁ ባለሙያዎች ሆነዋል። በአርአያነት ባለው አፈጻጸም፣ ተማሪዎቻችን
                የሚያስመሰግኑ የውጤት ነጥቦችን አግኝተዋል፣ ይህም የትጋት እና ታታሪነት ማረጋገጫ ነው። ይህ ምእራፍ
                የአካዳሚክ ውጤቶቻቸውን የሚያከብር ብቻ ሳይሆን በ[ክፍል/መስክ] ውስጥ ሙያዎችን ለማሟላት ተስፋ ሰጪ
                ጉዞ መጀመሩን ያሳያል።
              </span>
              {/* <h2>CourseName</h2>
        <span style={{ fontSize: "1rem" }}>DateRange</span>
        <div style={{ marginTop: "3rem", display: "inline-block" }}>
          <span style={{ fontSize: "1rem" }}>SignatureDetails</span>
        </div> */}
            </div>
            <div
              style={{
                width: "10px",
                backgroundColor: "black",
                height: "100%",
              }}
            ></div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                paddingLeft: "20px",
              }}
            >
              <p
                style={{
                  color: "#00f",
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  fontStyle: "italic",
                  marginBlock: "1rem",
                }}
              >
                To ______________________________________
              </p>
              <span style={{ fontSize: "1.4rem" }}>
                We are delighted to announce the successful completion of the
                [Department/Field] course in our TVET training program. From
                [Start Year] to [End Year], our dedicated students have immersed
                themselves in the rigorous curriculum of [Department/Field],
                demonstrating unparalleled commitment and enthusiasm. Throughout
                this period, they have honed their skills and expertise,
                emerging as proficient professionals ready to excel in their
                chosen fields. With exemplary performance, our students have
                earned commendable grade points, a testament to their diligence
                and hard work. This milestone not only celebrates their academic
                achievements but also signifies the beginning of a promising
                journey towards fulfilling careers in [Department/Field]
              </span>
              {/* <h2>CourseName</h2>
        <span style={{ fontSize: "1rem" }}>DateRange</span>
        <div style={{ marginTop: "3rem", display: "inline-block" }}>
          <span style={{ fontSize: "1rem" }}>SignatureDetails</span>
        </div>
      </div> */}
            </div>
          </div>
          <div style={{ marginTop: "3rem", display: "inline-block" }}>
            <span style={{ fontSize: "1rem" }}>SignatureDetails</span>
          </div>
        </div>
      </div>

      <button style={{ marginTop: "3rem" }} onClick={handleGeneratePDF}>
        Generate and Upload PDF
      </button>
    </>
  );
};

export default Certificate;
