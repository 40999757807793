/* eslint-disable react/prop-types */
import { DataGrid } from "@mui/x-data-grid";
import { theme } from "./theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "./DeleteModal";
import { useEffect, useMemo, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Button, TextField, colors } from "@mui/material";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import Modal from "./Modal";
import Certificate from "./Certificate";

export default function DataTable({ studentData }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteId, setId] = useState("");
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [failur, setShowFailur] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [approve, setApprove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [certType, setCertType] = useState(
    "Temporary Completion Certification"
  );

  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = () => {
    setApprove(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "middleName", headerName: "Middle name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },

    // {
    //   field: "email",
    //   headerName: "Email",
    //   width: 230,
    // },
    {
      field: "department",
      headerName: "Department",
      sortable: false,
      width: 210,
    },

    {
      field: "program",
      headerName: "Program",
      width: 190,
    },
    {
      field: "modality",
      headerName: "Modality",
      width: 160,
    },
    {
      field: "batch",
      headerName: "Batch",
      width: 160,
    },
    { field: "sex", headerName: "Sex", width: 130 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 190,
    // },
    {
      field: "status",
      headerName: "Status",
      width: 190,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color={params.row.status ? "success" : "error"}
            style={{ marginRight: "20px" }}
            // onClick={handleExportCSV}
          >
            {params.row.status ? "Prepared" : "Not Prepared"}
          </Button>
          {/* <EditIcon
            style={{ cursor: "pointer", color: "green" }}
            onClick={() => navigate(`/update-student/${params.row._id}`)}
          /> */}
        </div>
      ),
    },
    {
      field: "startYear",
      headerName: "Start Year",
      width: 160,
    },
    {
      field: "endYear",
      headerName: "End Year",
      width: 160,
    },
    {
      field: "level",
      headerName: "Level",
      width: 160,
    },
    {
      field: "gpa",
      headerName: "GPA",
      width: 160,
    },
    {
      field: "delete",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <div>
          <DeleteIcon
            style={{ cursor: "pointer", color: "red" }}
            // onClick={() => handleDelete(params.row._id)}
            onClick={() => handleClickOpen(params.row._id)}
          />
          <EditIcon
            style={{ cursor: "pointer", color: "green" }}
            onClick={() => navigate(`/update-student/${params.row._id}`)}
          />
        </div>
      ),
    },
  ];

  const rowss = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  ];

  const rows = useMemo(() => {
    return (
      (studentData.length > 0 &&
        studentData?.map((data) => ({
          _id: data?._id,
          id: data?.id,
          firstName: data?.firstName,
          firstNameAm: data?.firstNameAm,
          middleName: data?.middleName,
          middleNameAm: data?.middleNameAm,
          lastName: data.lastName,
          lastNameAm: data?.lastNameAm,
          department: data.department?.name,
          program: data.program?.name,
          modality: data.modality,
          batch: data.batch?.name,
          sex: data.sex,
          status: data.status,
          startYear: data.startYear,
          endYear: data.endYear,
          level: data.level,
          gpa: data.gpa,
        }))) ||
      []
    );
  }, [studentData]);

  // const handleRowClick = (params) => {
  //   const studentId = params.row.id;
  //   navigate(`/Student/subjects/${studentId}`);
  // };
  console.log(studentData);
  const handleDelete = async () => {
    const encodedId = encodeURIComponent(deleteId);
    try {
      const response = await axios.delete(
        `https://alliance-exam-server.onrender.com/api/students/${encodedId}`
      );
      handleClose();
      setShowPopup(true);
      setMessage("Student deleted successsfully");
      setId("");
    } catch (error) {
      setShowFailur(true);
      setMessage("error in deleting students, error");
      setId("");
      handleClose();
    }
  };

  const handleRowClick = (params) => {
    if (!params || !params.row) {
      return;
    }
    const { id } = params.row;
    console.log(id);
    const selectedIndex = selectedRows.findIndex((row) => row.id === id);

    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, params.row];
    } else {
      // Row is already selected, remove it from the selection
      newSelectedRows = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ];
    }
    setSelectedRows(newSelectedRows);
    console.log(newSelectedRows);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowFailur(false);
      setShowPopup(false);
    }, 10000);
  }, [showPopup, failur]);

  const handleExportCSV = () => {
    if (studentData) {
      setExportData([
        Object.keys(studentData[0]).filter(
          (column) =>
            column !== "_id" &&
            column !== "password" &&
            column !== "examStatus" &&
            columns !== "answer" &&
            column !== "__v"
        ), // Header row
        ...studentData?.map(
          ({ _id, password, examStatus, answer, __v, ...row }) =>
            Object.values(row)
        ),
      ]);
    }
  };

  const handleExportExcel = () => {
    const formattedData = studentData?.map((data) => ({
      ID: data.id,
      "First Name": data.firstName,
      "Middle Name": data.middleName,
      "Last Name": data.lastName,
      Department: data.department,
      Sex: data.sex,
      "Remain Time": data.remainTime,
      Result: data.result,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  useEffect(() => {
    if (approve) {
      setLoading(true);
      generateCertificatesAndUploadPDF();
    }
  }, [approve]);
  const generateCertificatesAndUploadPDF = async () => {
    try {
      const response = await axios.post(
        "https://alliance-certificate.onrender.com/api/users/upload-pdfs",
        {
          certificates: selectedRows,
          pdfName,
          certType,
        }
      );

      console.log("PDFs saved:", response.data);
      setLoading(false);
      setApprove(false);
      setOpenModal(false);
      setShowPopup(true);
      setMessage(
        `${selectedRows.length} Certificate is Generated Successfully`
      );
      window.location.reload();
      return response.data; // Return saved PDF data
    } catch (error) {
      console.error("Error generating certificates and uploading PDF:", error);
      setApprove(false);
      setOpenModal(false);
      setShowFailur(true);
      setMessage("Error generating certificates and uploading PDF:");
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Stack sx={{ width: "100%" }} spacing={2} style={{ marginTop: "30px" }}>
        {showPopup && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}

        {failur && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
      </Stack>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        {/* <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "20px" }}
          // onClick={handleExportCSV}
        >
          <CSVLink
            data={exportData}
            filename={"data.csv"}
            className="MuiButtonBase-root MuiButton-root MuiButton-contained"
            target="_blank"
            onClick={handleExportCSV}
            style={{ color: "white", textDecoration: "none" }}
          >
            Export as CSV
          </CSVLink>{" "}
        </Button> */}
        {/* <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "20px" }}
          // onClick={handleExportCSV}
        >
          Export as CSV
        </Button> */}
        {/* <Button variant="contained" color="primary" onClick={handleExportExcel}>
          Export as Excel
        </Button> */}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          marginBottom: "50px",
        }}
      >
        <TextField
          id="pdf-name"
          label="department_year/name"
          variant="outlined"
          style={{ marginRight: "20px" }}
          onChange={(event) => setPdfName(event.target.value)}
        />
        <TextField
          id="type"
          label="Temporary Completion Certification"
          variant="outlined"
          style={{ marginRight: "20px" }}
          onChange={(event) => setCertType(event.target.value)}
        />
        <Button
          variant="contained"
          color="success"
          onClick={() => setOpenModal(true)}
        >
          Generate Certificate
        </Button>
      </div>
      <div style={{ maxWidth: "100px" }}>
        <StyledDataGrid
          rows={studentData ? rows : rowss}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20, 25, 50]}
          checkboxSelection
          onRowClick={handleRowClick}
          onSelectionModelChange={(newSelection) => {
            console.log(newSelection);

            if (newSelection.length > 0) {
              const selectedRow = rows.find(
                (row) => row.id === newSelection[0]
              );

              handleRowClick({ row: selectedRow });
            } else {
              handleRowClick({ row: null });
            }
          }}
        />
      </div>
      {open && (
        <DeleteModal
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          handleDelete={handleDelete}
        />
      )}
      {openModal && (
        <Modal
          open={openModal}
          data={selectedRows}
          handleCloseModal={handleCloseModal}
          setOpenApprove={handleApprove}
          loading={loading}
        >
          <Certificate {...selectedRows} />
        </Modal>
      )}
    </div>
  );
}
const StyledDataGrid = styled(DataGrid)(() => ({
  maxWidth: "100vw",
  minWidth: "min-content",
  width: "100%",
  height: "692px",
  backgroundColor: theme.colors.white[9],
  fontWeight: 400,
  justifyContent: "space-evenly",
  "& .MuiDataGrid-toolbarContainer": {
    padding: ".8rem .5rem",
  },

  "& .MuiDataGrid-columnHeaders": {
    borderBottom: `1px solid ${theme.colors.border.default}`,
    backgroundColor: theme.colors.primary[0],
  },
  "& .MuiDataGrid-columnHeader": {
    borderBottom: `1px solid ${theme.colors.border.default}`,

    "& .MuiDataGrid-columnHeaderTitle": {
      padding: "0 auto",
      margin: "0 auto",
    },
  },

  "& .MuiDataGrid-row": {
    cursor: "pointer",
    backgroundColor: theme.colors.white[9],
    "&:hover": {
      backgroundColor: theme.colors.primary[2],
    },
    "&.Mui-selected": {
      backgroundColor: theme.colors.primary[2],
      "&:hover": {
        backgroundColor: theme.colors.primary[0],
      },
    },
  },
}));
