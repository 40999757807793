import { Container, Button, Modal, CloseButton } from "react-bootstrap";

const PreviwModal = ({ showModal, setShowModal, previewUrl, pdfName }) => {
  return (
    <Container>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        fullscreen={true}
        style={{ marginTop: "60px" }}
      >
        <Modal.Header style={{ position: "relative" }}>
          <Modal.Title
            style={{
              position: "absolute",
              top: "30px",
              left: "1rem",
              transform: "translateY(-50%)",
              color: "red",
            }}
          >
            {pdfName}
          </Modal.Title>
          <CloseButton
            onClick={() => setShowModal(false)}
            style={{
              position: "absolute",
              top: "30px",
              right: "1rem",
              transform: "translateY(-50%)",
              color: "red",
            }}
          />
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <embed
            src={previewUrl}
            width="100%"
            height="100%"
            style={{ border: 0 }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default PreviwModal;
