/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Container } from "@mui/material";
import Popup from "../Ui/Popup";
import styled from "styled-components";
import BasicSelect from "../Ui/SelectNew";
import { useDispatch, useSelector } from "react-redux";
import { setStatus, setStudentsStart, setError } from "../../pages/slice/index";
import ExcelUploader from "./ExcelUploud";
import axios from "axios";
const modalities = [
  { name: "Regular", _id: "Regular" },
  { name: "Extension", _id: "Extension" },
  { name: "Weekend", _id: "Weekend" },
];

const StudentRegistrationForm = () => {
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  const status = useSelector((state) => state.student.status);
  const error = useSelector((state) => state.student.error);
  const [flag, setFlag] = useState(false);

  const [departments, setDepartmets] = useState([]);
  const [department, setDepartment] = useState("");
  const [programData, setPrograms] = useState([]);
  const [batchData, setBatchs] = useState([]);
  const [sectionData, setSections] = useState([]);
  const [program, setProgram] = useState("");
  const [data, setData] = useState({
    department: "",
    program: "",
    batch: "",
    modality: "",
  });
  const [studentData, setStudentData] = useState({
    id: "",
    firstName: "",
    firstNameAm: "",
    middleName: "",
    middleNameAm: "",
    lastName: "",
    lastNameAm: "",
    email: "",
    startYear: "",
    endYear: "",
    programLevel: "",
    sex: "",
    department: "",
    program: "",
    modality: "",
    batch: "",
    level: "",
    gpa: "",
  });

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [failur, setShowFailur] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const submitHandler = (event) => {
    event.preventDefault();
    console.log(studentData);
    setLoader(true);

    dispatch(setStudentsStart({ studentData }));

    // setLoader(false);
    // setShowPopup(true);
    // setMessage("Research Successfully created");
  };

  if (status) {
    setFlag(true);
    setMessage(status);
    setLoader(false);
    setShowPopup(true);
    dispatch(setStatus(""));
    //   setStudentData((prevData) => ({
    //     ...prevData,
    //     firstName: "",
    //     phoneNo: "",
    //     address: "",
    //     topic: "",
    //     pdfUrl: "",
    //     department: "",
    //     description: "",
    //   }));
  }
  if (error) {
    setFlag(false);
    setMessage(error);
    setLoader(false);
    setShowPopup(true);
    dispatch(setError(null));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/department`
        );
        setDepartmets(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchProgram = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/program`
        );
        setPrograms(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    const fetchBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/batches`
        );
        setBatchs(response.data);
      } catch (error) {
        console.error("Error fetching user:", error.message);
      }
    };
    fetchBatch();
    fetchProgram();
    fetchDepartment();
  }, []);
  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <form onSubmit={submitHandler}>
        <Box mb={2}>
          <Typography variant="h6">Register Student</Typography>
        </Box>
        <div style={{ marginBottom: "2.5rem" }}>
          <ExcelUploader
            setMessage={setMessage}
            setShowPopup={setShowPopup}
            setShowFailur={setShowFailur}
          />
        </div>
        <Grid container spacing={4}>
          <React.Fragment>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="Id"
                variant="outlined"
                name="id"
                value={studentData.id}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                name="firstName"
                value={studentData.firstName}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Middle Name"
                variant="outlined"
                name="middleName"
                value={studentData.middleName}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={studentData.lastName}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
          </React.Fragment>
        </Grid>
        <h4>In Amharic:</h4>
        <Grid container spacing={4}>
          <React.Fragment>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="የመጀመሪያ ስም"
                variant="outlined"
                name="firstNameAm"
                value={studentData.firstNameAm}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="የአባት ስም"
                variant="outlined"
                name="middleNameAm"
                value={studentData.middleNameAm}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />{" "}
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="የአያት ስም"
                variant="outlined"
                name="lastNameAm"
                value={studentData.lastNameAm}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
          </React.Fragment>
        </Grid>
        <br />
        <Grid container spacing={4}>
          <React.Fragment>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                inputProps={{ min: 0 }}
                value={studentData.email}
                onChange={handleInputChange}
                sx={styles.inputField}
                // required
              />
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                fullWidth
                label="Program"
                variant="outlined"
                name="program"
                value={studentData.program}
                onChange={handleInputChange}
                sx={styles.inputField}
              />
            </Grid> */}
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Level"
                variant="outlined"
                name="level"
                value={studentData.level}
                onChange={handleInputChange}
                sx={styles.inputField}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="GPA"
                variant="outlined"
                name="gpa"
                value={studentData.gpa}
                onChange={handleInputChange}
                sx={styles.inputField}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="Start Year(G.C)"
                variant="outlined"
                type="text"
                name="startYear"
                inputProps={{ min: 0 }}
                value={studentData.startYear}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="End Year(G.C)"
                variant="outlined"
                type="text"
                name="endYear"
                inputProps={{ min: 0 }}
                value={studentData.endYear}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <BasicSelect
                label="Program Level"
                onChange={handleInputChange}
                name="programLevel"
                value={studentData.programLevel}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                fullWidth
                label="Department"
                variant="outlined"
                type="text"
                name="department"
                inputProps={{ min: 0 }}
                value={studentData.department}
                onChange={handleInputChange}
                sx={styles.inputField}
                required
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={12} lg={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  name="department"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.department}
                  label="Department"
                  onChange={handleChange}
                >
                  {departments?.map((dept) => (
                    <MenuItem
                      key={dept._id}
                      value={dept._id}
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Program</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="program"
                  id="demo-simple-select"
                  value={studentData.program}
                  label="Department"
                  onChange={handleChange}
                >
                  {programData?.map((prog) => (
                    <MenuItem
                      key={prog._id}
                      value={prog._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {prog.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Batch</InputLabel>
                <Select
                  name="batch"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.batch}
                  label="Department"
                  onChange={handleChange}
                >
                  {batchData?.map((bach) => (
                    <MenuItem
                      key={bach._id}
                      value={bach._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {bach.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-label">Modality</InputLabel>
                <Select
                  name="modality"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={studentData.modality}
                  label="Department"
                  onChange={handleChange}
                >
                  {modalities?.map((mod) => (
                    <MenuItem
                      key={mod._id}
                      value={mod._id}
                      sx={{
                        // height: "40px",
                        textAlign: "left",
                      }}
                    >
                      {mod.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* ggggggggggggggggggggg */}

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <BasicSelect
                label="Sex"
                onChange={handleInputChange}
                name="sex"
                value={studentData.sex}
              />
            </Grid>
          </React.Fragment>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Register"
                )}
              </Button>
            </Box>
          </Grid>
          <Popup
            flag={flag}
            message={message}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
          />
        </Grid>
      </form>
    </Container>
  );
};

export default StudentRegistrationForm;

const styles = {
  inputField: {
    "& .MuiInputLabel-root": {
      color: "#838080",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#838080",
    },
  },
};
const Card = styled.div`
  border: 1px solid "#f7f7f7";
  background-color: "#fff";
  padding: 1rem;
  margin: 1rem 0;
`;
