import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../Login/styles.module.css";
import { useDispatch } from "react-redux";

const Profile = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  return (
    <div className={styles.login_container}>
      <div className={styles.login_form_container}>
        <div className={styles.left}>
          <form className={styles.form_container}>
            {/* <h1>Login to Your Account</h1> */}
            <input
              type="email"
              placeholder="First Name: "
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Middle Name: "
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Last Name: "
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Email: "
              name="email"
              onChange={handleChange}
              value={data.email}
              required
              className={styles.input}
            />
          </form>
        </div>
        {/* <div className={styles.right}>
          <h1>New Here ?</h1>
          <Link to="/signup">
            <button type="button" className={styles.white_btn}>
              Sing Up
            </button>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
