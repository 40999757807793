import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  deletedId: "",
  status: "",
  loading: false,
  error: null,
};

const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setStudentsStart: (state, action) => {
      console.log("action");
      state.loading = true;
      state.error = null;
    },
    setStudentsSuccess: (state, action) => {
      state.loading = false;
      state.students = action.payload;
      state.status = action.payload.data.msg;
    },
    setStudentsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getStudentsStart: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getStudentsSuccess: (state, action) => {
      state.loading = false;
      state.students = action.payload;
    },
    getStudentsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteStudentStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSingleStudentStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getSingleStudentSuccess: (state, action) => {
      state.loading = false;
      state.students = action.payload;
    },
    getSingleStudentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteStudentSuccess: (state, action) => {
      state.loading = false;
      state.students = state.students.filter(
        (student) => student.id !== action.payload
      );
      state.status = action.payload.msg;
    },
    deleteStudentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateStudentsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateStudentsSuccess: (state, action) => {
      state.loading = false;
      state.students = state.students.map((student) =>
        student.id === action.payload.id ? action.payload : student
      );
      state.status = action.payload.msg;
    },
    updateStudentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setDeletedId: (state, action) => {
      state.deletedId = action.payload;
    },
    clearDeletedId: (state) => {
      state.deletedId = "";
    },
    setStatus: (state, action) => {
      console.log(action.payload);
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setStudentsStart,
  setStudentsSuccess,
  setStudentsFailure,
  getStudentsStart,
  getStudentsSuccess,
  getStudentsFailure,
  deleteStudentStart,
  getSingleStudentStart,
  getSingleStudentFailure,
  getSingleStudentSuccess,
  deleteStudentSuccess,
  deleteStudentFailure,
  updateStudentsStart,
  updateStudentsSuccess,
  updateStudentFailure,
  setDeletedId,
  clearDeletedId,
  setStatus,
  setError,
} = studentSlice.actions;

export default studentSlice.reducer;
