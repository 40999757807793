import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DepartmentForm from "./Department";
import ProgramForm from "./Program";
import BatchForm from "./Batch";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CollectionComponet() {
  return (
    <Box
      sx={{
        width: "100%",
        paddingLeft: "5%",
        marginBottom: "3rem",
        marginTop: "3rem",
      }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <DepartmentForm />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <ProgramForm />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <BatchForm />
        </Grid>
        {/* <Grid item xs={6}>
          <Item>4</Item>
        </Grid> */}
      </Grid>
    </Box>
  );
}
