import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./components/Main";
import Signup from "./components/Singup";
import Login from "./components/Login";
import React from "react";
import Dashboard from "./components/Ui/AdminDashboardComponent";
import { routes } from "./utils/routes";
import { v4 as uuid } from "uuid";
import StudentDashboard from "./components/Ui/StudentDashboard";

function App() {
  const user = localStorage.getItem("token");

  return (
    <React.Fragment>
      <Routes>
        {/* {user && <Route path="/" exact element={<Main />} />} */}
        {!localStorage.getItem("isAuthenticate") && (
          <Route path="/signup" exact element={<Signup />} />
        )}
        {!localStorage.getItem("isAuthenticate") && (
          <Route path="/login" exact element={<Login />} />
        )}
        {!localStorage.getItem("isAuthenticate") && (
          <Route path="/" element={<Navigate replace to="/login" />} />
        )}
      </Routes>
      {localStorage.getItem("isAuthenticate") && (
        <div>
          {/* <SideBar /> */}
          <StudentDashboard>
            <Routes>
              {routes.map((route) => (
                <React.Fragment key={uuid()}>
                  {route.isProtected ? (
                    <Route element={route.element} path={route.path} />
                  ) : (
                    <Route element={route.element} path={route.path} />
                  )}
                </React.Fragment>
              ))}
              <Route element={<div>Page not found</div>} path="*" />
            </Routes>
          </StudentDashboard>
        </div>
      )}
    </React.Fragment>
  );
}

export default App;
