import React, { useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";

const ExcelUploader = ({ setMessage, setShowPopup, setShowFailur }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    // Create a FormData object
    const formData = new FormData();

    // Append the selected file to the FormData object
    formData.append("file", selectedFile);

    axios
      .post(
        "https://alliance-certificate.onrender.com/api/users/excel-upload",
        formData
      )
      .then((response) => {
        setMessage("Student uploaded successfully");
        setShowPopup(true);
      })
      .catch((error) => {
        setMessage("Error uploading file:", error);
        setShowFailur(true);
        console.error("Error uploading file:", error);
      });
  };

  return (
    <div>
      <h2>Excel File Uploader</h2>
      <input type="file" onChange={handleFileSelect} />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleFileUpload}
        // className={classes.submitButton}
      >
        Upload Excel File
      </Button>
    </div>
  );
};

export default ExcelUploader;
