import React, { useState } from "react";
import {
  Modal,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import BasicTable from "./Table";

const TableModal = ({
  open,
  data,
  setOpenApprove,
  handleCloseModal,
  loading,
}) => {
  console.log(data);
  return (
    <>
      <Modal open={open} onClose={handleCloseModal}>
        <div style={{ width: "50vw", margin: "auto" }}>
          <h2 color="white">Selected Row Details</h2>
          {loading ? (
            <Box
              sx={{
                height: "200px",
                display: "flex",
                backgroundColor: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <BasicTable data={data} />
          )}
          <Button
            className="primary"
            style={{ backgroundColor: "blue", float: "right", color: "white" }}
            onClick={setOpenApprove}
          >
            {loading ? <CircularProgress /> : "Approve"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TableModal;
