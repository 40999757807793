import { Children, useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import StudentSideBar from "./StudentSideBar";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppBar, Drawer } from "./styles";
import AccountMenu from "./AccountMenu";
import AdminDashboard from "./Dashboard";

const StudentDashboard = ({ children }) => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (window.innerWidth < 500) {
      console.log("not display");
    } else {
      toggleDrawer();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 500);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          open={open}
          position="absolute"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <Toolbar sx={{ pr: "24px" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Admin Dashboard
            </Typography>
            <AccountMenu />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={open ? styles.drawerStyled : styles.hideDrawer}
        >
          <Toolbar
            sx={styles.toolBarStyled}
            style={{ backgroundColor: "rgb(0, 0, 51)" }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon style={{ color: "white" }} />
            </IconButton>
          </Toolbar>
          <Divider style={{ borderColor: "black", color: "black" }} />
          <List
            component="nav"
            style={{
              backgroundColor: "rgb(0, 0, 51)",
              color: "white",
              height: "100%",
            }}
          >
            <StudentSideBar />
          </List>
        </Drawer>
        <Box component="main" sx={styles.boxStyled}>
          <Toolbar />
          {/* <Routes> */}
          {/* <Route path="/dashboard/student" element={<StudentHomePage />} /> */}
          {/* <Route path="*" element={<Navigate to="/" />} /> */}
          {/* <Route path="/Student/dashboard" element={<StudentHomePage />} /> */}
          {/* <Route path="/Student/profile" element={<StudentProfile />} />

            <Route path="/Student/subjects" element={<StudentSubjects />} />
            <Route path="/Student/attendance" element={<ViewStdAttendance />} />
            <Route path="/Student/complain" element={<StudentComplain />} /> */}

          {/* <Route path="/logout" element={<Logout />} /> */}
          {/* </Routes> */}
          {children}
        </Box>
      </Box>
    </>
  );
};

export default StudentDashboard;

const styles = {
  boxStyled: {
    backgroundColor: (theme) =>
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  toolBarStyled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    px: [1],
  },
  drawerStyled: {
    display: "flex",
  },
  hideDrawer: {
    display: "flex",
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
};
